<template>
<div>
    <div class="wrap">
        <form name="mfrm" action="/menu_management_status/index" method="post">
            <div class="con">
                <div class="con_table">
                    <div class="con_top mb-10">
                        <h2><span>■</span> 고객정보</h2>
                    </div>

                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <th class="w-120px">고객명</th>
                                <td class="left pl-20">
                                    {{clientName}}
                                </td>
                                <th class="w-120px">연령</th>
                                <td class="left pl-20">
                                    {{ageRange}}
                                </td>
                            </tr>
                            <tr>
                                <th class="w-120px">센터명</th>
                                <td class="left pl-20">
                                    {{centerName}}
                                </td>
                                <th class="w-120px">상담사명</th>
                                <td class="left pl-20">
                                    {{staffName}}
                                </td>
                            </tr>
                            <tr>
                                <th>상담일</th>
                                <td class="left pl-20">
                                    {{consDate}}
                                </td>
                                <th>상담시간</th>
                                <td class="left pl-20">
                                    {{consTime}}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    상담유형
                                </th>
                                <td class="left pl-20">
                                    {{typeName}}
                                </td>
                                <th>
                                    상담구분
                                </th>
                                <td class="left pl-20">
                                    {{productName}}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    상담주제
                                </th>
                                <td class="left pl-20"> 
                                    {{ idxAgendaFirstNm }} / {{idxAgendaSecondNm}}
                                </td>
                                <th>
                                    사용가능회기
                                </th>
                                <td class="left pl-20">
                                    {{consCountUse}} 회 (총{{clientConsCount}}회)
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="con_top mb-10">
                        <h2><span>■</span> 상담일지 상세보기</h2>
                    </div>

                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <th>회기 유형</th>
                                <td class="left pl-20" colspan="3">
                                    {{idxGenreMetaNm}}
                                </td>
                            </tr>
                            <tr>
                                <th>상담 형태</th>
                                <td class="left pl-20" colspan="3">
                                    {{idxTypeMetaNm}}
                                </td>
                            </tr>
                            <tr>
                                <th>전체상담의목표</th>
                                <td class="left pl-20" colspan="3">
                                    {{goal}}
                                </td>
                            </tr>
                            <tr>
                                <th>상담계획</th>
                                <td class="left pl-20" colspan="3">
                                    {{plan}}
                                </td>
                            </tr>
                            <tr>
                                <th>위험단계</th>
                                <td class="left pl-20" colspan="3">
                                    {{dangerStepTitle}}: <br>
                                    {{dangerStepInfo}}
                                </td>
                            </tr>
                            <tr>
                                <th>주 호소 문제</th>
                                <td class="left pl-20" colspan="3">
                                    {{mainProblem}}
                                </td>
                            </tr>
                            <tr>
                                <th>상담과정</th>
                                <td class="left pl-20" colspan="3">
                                    {{consProcess}}
                                </td>
                            </tr>
                            <tr>
                                <th class="w-120px">상담내용</th>
                                <td class="left pl-20">
                                    {{consContents}}
                                </td>
                            </tr>
                            <tr>
                                <th class="w-120px">상담사소견</th>
                                <td class="left pl-20">
                                    {{consOpinion}}
                                </td>
                            </tr>
                            <tr>
                                <th class="w-120px">심리검사</th>
                                <td class="left pl-20">
                                    {{mentalTest}}
                                </td>
                            </tr>
                            <tr>
                                <th class="w-120px">이후계획</th>
                                <td class="left pl-20">
                                    {{nextPlan}}
                                </td>
                            </tr>
                            <tr>
                                <th class="w-120px">첨부파일</th>
                                <td class="left pl-20">
                                    <div v-if="attachFileUrl && attachFileUrl !== 'null'">
                                        <a target="blank" :href="attachFileUrl" class="w-300px pointer txt-black">{{attachFileName}}</a>
                                    </div>
                                    <div v-else>파일 없음</div>

                                    <div v-if="attachFileUrl2 && attachFileUrl2 !== 'null'">
                                        <a target="blank" :href="attachFileUrl2" class="w-300px pointer txt-black">{{attachFileName2}}</a>
                                    </div>
                                    <div v-else>파일 없음</div>

                                    <div v-if="attachFileUrl3 && attachFileUrl3 !== 'null'">
                                        <a target="blank" :href="attachFileUrl3" class="w-300px pointer txt-black">{{attachFileName3}}</a>
                                    </div>
                                    <div v-else>파일 없음</div>

                                    <div v-if="attachFileUrl4 && attachFileUrl !== 'null'">
                                        <a target="blank" :href="attachFileUrl4" class="w-300px pointer txt-black">{{attachFileName4}}</a>
                                    </div>
                                    <div v-else>파일 없음</div>

                                    <div v-if="attachFileUrl5 && attachFileUrl5 !== 'null'">
                                        <a target="blank" :href="attachFileUrl5" class="w-300px pointer txt-black">{{attachFileName5}}</a>
                                    </div>
                                    <div v-else>파일 없음</div>
                                    
                                </td>
                            </tr>
                            <!-- <tr>
                                <th class="w-120px">다음상담</th>
                                <td class="left pl-20">
                                    <span class="pointer underline">연장</span>
                                </td>
                            </tr> -->
                        </tbody>
                    </table>

                    <div class="btns3 mgB80">
                        <a class="btn_sms mr-10 pointer" @click="prev()">목록</a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import {
    GetUrlParams
} from '@/utils/common.js'
export default {
    data: () => ({
        
        /* 메타데이터 리스트 */
        agendList:[], 
        agendSecondList:[],
        genreList:[],
        typeList:[],

        attachFile: '', // 상담일지 첨부파일
        idx: -1, // resrvation idx
        idxReport: -1, // report idx
        // reportVO 값
        attachFileName: '',
        attachFileName2: '',
        attachFileName3: '',
        attachFileName4: '',
        attachFileName5: '',
        clientName: '',
        consOpinion: '',
        mentalTest: '',
        nextPlan: '',
        consContents: '',
        attachFileUrl: '',
        attachFileUrl2: '',
        attachFileUrl3: '',
        attachFileUrl4: '',
        attachFileUrl5: '',
        processStatus: '',
        dangerStepTitle: '',
        dangerStepInfo: '',
        mainProblem: '',
        consProcess: '',
        idxAgendaFirst:'',
        idxAgendaSecond:'',
        idxAgendaFirstNm:'',
        idxAgendaSecondNm:'',
        idxGenreMeta: '',/* 회기유형 */
        idxTypeMeta: '',/* 상담평태 */
        idxGenreMetaNm: '',/* 회기유형 */
        idxTypeMetaNm: '',/* 상담평태 */

        // reservationVO 값
        typeName: '',
        consDate: '',
        consTime: '',
        reservationStatus: '',
        memo: '',
        centerName: '',
        goal: '',
        plan: '',
        consCount: 0,
        consCountRest: 0,

        //clientVO 값
        idxCrmClient: -1,
        gubun: '',
        productName: '',
        subjectName: '',
        permission: '',
        type: 'REPORT',
        staffName: '',
        ageRange:'',
        consCountUse:0,
        clientConsCount : 0,
    }),

    mounted() {
        var oParams = GetUrlParams()
        this.idx = oParams.idx;
        
        this.getReport();
    },

    methods: {
        // 상담일지 내용
        getReport() {
            this.axios.get(`/api/v1/cons/report/${this.idx}`, {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.ageRange = res.data.clientVO.ageRange;
                        this.clientName = res.data.clientVO.name
                        this.consDate = res.data.reservationVO.consDate.slice(0, 4) + "년 " + res.data.reservationVO.consDate.slice(5, 7) + "월 " + res.data.reservationVO.consDate.slice(8, 10) + "일"
                        if(res.data.reservationVO.consTime.length >= 84) {
                            res.data.reservationVO.consTime = res.data.reservationVO.consTime.substring(0,84) + '\n' + res.data.reservationVO.consTime.substring(84)
                        }
                        this.consTime = res.data.reservationVO.consTime

                        this.productName = res.data.clientVO.productName;
                        this.typeName = res.data.clientVO.typeName;
                        this.idxAgendaFirst = res.data.reportVO.idxAgendaFirst;
                        this.idxAgendaSecond = res.data.reportVO.idxAgendaSecond;
                        this.consCountUse = res.data.clientVO.consCountUse;
                        this.clientConsCount = res.data.clientVO.consCount;

                        this.subjectName = res.data.clientVO.subjectName
                        this.consCount = res.data.clientVO.consCount
                        this.consCountRest = res.data.clientVO.consCountRest
                        this.centerName = res.data.clientVO.centerName
                        this.dangerStepTitle = res.data.reportVO.dangerStepTitle
                        this.dangerStepInfo = res.data.reportVO.dangerStepInfo;

                        this.idxGenreMeta = res.data.reportVO.idxGenreMeta;
                        this.idxTypeMeta = res.data.reportVO.idxTypeMeta;

                        this.goal = res.data.clientVO.goal
                        this.mainProblem = res.data.reportVO.mainProblem
                        this.plan = res.data.clientVO.plan
                        this.consProcess = res.data.reportVO.consProcess
                        this.consOpinion = res.data.reportVO.consOpinion
                        this.mentalTest = res.data.reportVO.mentalTest
                        this.nextPlan = res.data.reportVO.nextPlan
                        this.consContents = res.data.reportVO.consContents

                        this.attachFileName = res.data.reportVO.attachFileName
                        this.attachFileName2 = res.data.reportVO.attachFileName2
                        this.attachFileName3 = res.data.reportVO.attachFileName3
                        this.attachFileName4 = res.data.reportVO.attachFileName4
                        this.attachFileName5 = res.data.reportVO.attachFileName5

                        this.attachFileUrl = res.data.reportVO.attachFileUrl
                        this.attachFileUrl2 = res.data.reportVO.attachFileUrl2
                        this.attachFileUrl3 = res.data.reportVO.attachFileUrl3
                        this.attachFileUrl4 = res.data.reportVO.attachFileUrl4
                        this.attachFileUrl5 = res.data.reportVO.attachFileUrl5

                        this.idxCrmClient = res.data.clientVO.idx

                        this.permission = res.data.reportVO.permission
                        this.idxReport = res.data.reportVO.idx
                        this.staffName = res.data.reportVO.staffName;

                        this.getAgendaMeta();/* 상위 주제 리스트 */
                        this.getGenreMeta(); /* 회기 리스트 */
                        this.getTypeMeta(); /*  상담형태 리스트 */
                    }
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },
        // 상담예약관리 페이지로 돌아가기
        prev() {
            history.back()
        },

        /**
         * agend대주제 리스트
         */
        getAgendaMeta(){
            this.axios.get('/api/v1/cons/report/agenda_meta',{}).then((response)=>{
                this.agendList = response.data.data;
                const selected = this.agendList.filter(v=> v.idx=== this.idxAgendaFirst);
                this.idxAgendaFirstNm = selected[0].cons_title;
                this.getAgendaSecondMeta(this.idxAgendaFirst);
            })
            .catch(err => {
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert('관리자에게 문의하세요')
                }
            })
        },

        /**
         * @param {idx} 상위대주제
         */
        getAgendaSecondMeta(idx){
            this.axios.get(`/api/v1/cons/report/agenda_meta/${idx}`,{}).then((response)=>{
                this.agendSecondList = response.data.data;
                const selected = this.agendSecondList.filter(v=> v.idx=== this.idxAgendaSecond);
                this.idxAgendaSecondNm = selected[0].cons_title;
            })
            .catch(err => {
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert('관리자에게 문의하세요')
                }
            });
        },

        /**
         * 회기유형 목록
         */
        getGenreMeta(){
            this.axios.get(`/api/v1/cons/report/genre_meta`,{}).then((response)=>{
                this.genreList = response.data.data;
                const selected = this.genreList.filter(v=>v.idx === this.idxGenreMeta);
                this.idxGenreMetaNm = selected[0].cons_title;
            })
            .catch(err => {
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert('관리자에게 문의하세요')
                }
            });
        },

        /**
         * 상담형태 목록
         */
        getTypeMeta(){
            this.axios.get(`/api/v1/cons/report/type_meta`,{}).then((response)=>{
                this.typeList = response.data.data;
                const selected = this.typeList.filter(v=>v.idx === this.idxTypeMeta);
                this.idxTypeMetaNm = selected[0].cons_title;
            })
            .catch(err => {
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert('관리자에게 문의하세요')
                }
            });
        }

    }
}
</script>
